import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "!mt-10 flex flex-col gap-10 lg:mt-20 lg:gap-20",
    "data-archive-events-featured-posts": ""
};
const _hoisted_2 = {
    key: 0,
    class: "container"
};
const _hoisted_3 = { class: "wysiwyg w-full lg:w-2/3" };
const _hoisted_4 = {
    class: "!overflow-visible",
    "data-archive-events-posts": ""
};
const _hoisted_5 = { class: "flex flex-col gap-10 lg:gap-20" };
const _hoisted_6 = { class: "container flex flex-wrap items-start justify-center gap-5" };
const _hoisted_7 = {
    key: 0,
    class: "container text-center"
};
const _hoisted_8 = {
    key: 1,
    class: "grid-layout container"
};
const _hoisted_9 = {
    key: 3,
    class: "grid-layout container"
};
const _hoisted_10 = {
    key: 1,
    class: "lg:!mt-32"
};
const _hoisted_11 = { "data-quicklinks": "" };
import { computed, defineAsyncComponent, onMounted } from 'vue';
import FilterInput from '@atoms/FilterInput.vue';
import DatePicker from '@atoms/DatePicker.vue';
import FeaturedPosts from '@molecules/FeaturedPosts.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveWhatsOn',
    props: {
        intro: {},
        featuredPosts: {},
        quickLinksSections: {},
        posts: {},
        pagination: {},
        filterOptions: {},
        pastEvents: {},
        virtualEvents: {},
        eventsSeries: {},
        eventSeriesTitle: {}
    },
    setup(__props) {
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const SignpostsBlock = defineAsyncComponent(() => import('@blocks/SignpostsBlock.vue'));
        const PostCard = defineAsyncComponent(() => import('@atoms/PostCard.vue'));
        const ArchivePagination = defineAsyncComponent(() => import('@molecules/ArchivePagination.vue'));
        const pastEventsData = computed(() => {
            const posts = [];
            posts.push({
                title: props.pastEvents.title,
                url: '/archive/past-events/',
                image: props.pastEvents.image,
            });
            return posts;
        });
        const hasVirtualEvents = computed(() => {
            return props.virtualEvents && Object.keys(props.virtualEvents).length > 0;
        });
        const hasFeaturedPosts = computed(() => {
            return props.featuredPosts && Object.keys(props.featuredPosts).length > 0;
        });
        const hasEventsSeries = computed(() => {
            return (props.eventsSeries &&
                Object.keys(props.eventsSeries).length > 0 &&
                currentPage.value == 1);
        });
        const displayPagination = computed(() => {
            return (props.pagination &&
                Object.keys(props.pagination.pages).length > 1 &&
                Object.keys(props.posts).length > 0);
        });
        //check what page we are on based on url eg /page/2
        const currentPage = computed(() => {
            const url = window.location.href;
            const page = url.match(/\/page\/\d+/g);
            if (page) {
                // Use parseInt to convert the matched string to a number
                return parseInt(page[0].replace('/page/', ''), 10);
            }
            // If no match is found, default to 1
            return 1;
        });
        const firstThreePosts = computed(() => {
            if (currentPage.value > 1) {
                return [];
            }
            const limited = {};
            let count = 0;
            for (const key in props.posts) {
                if (count >= 3)
                    break;
                limited[key] = props.posts[key];
                count++;
            }
            return limited;
        });
        const hasFirstThreePosts = computed(() => {
            return Object.keys(firstThreePosts.value).length > 0;
        });
        const remainingPosts = computed(() => {
            if (currentPage.value > 1) {
                return props.posts;
            }
            const remaining = {};
            let count = 0;
            for (const key in props.posts) {
                if (count < 3) {
                    count++;
                    continue;
                }
                remaining[key] = props.posts[key];
                count++;
            }
            return remaining;
        });
        const hasRemainingPosts = computed(() => {
            return Object.keys(remainingPosts.value).length > 0;
        });
        const props = __props;
        const addFilterSelectionToUrl = (selectedValue) => {
            const newUrl = new URL(window.location.href);
            // Remove 'filtered' parameter if 'clear-filter' is selected
            if (selectedValue === 'clear-filter') {
                newUrl.searchParams.delete('filtered');
            }
            else {
                newUrl.searchParams.set('filtered', selectedValue);
            }
            // Remove '/page/' and a number if present
            const cleanedUrl = newUrl.toString().replace(/\/page\/\d+/g, '');
            window.history.pushState({}, '', cleanedUrl);
            window.location.reload();
        };
        const scrollToContentAfterFilter = () => {
            const content = document.querySelector('[data-archive-events-posts]');
            setTimeout(() => {
                content.scrollIntoView({ behavior: 'smooth' });
            }, 300);
        };
        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const filteredContent = urlParams.get('filtered');
            const dateSelected = urlParams.get('date');
            if (filteredContent || dateSelected) {
                scrollToContentAfterFilter();
            }
        });
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (hasFeaturedPosts.value)
                    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                        (_ctx.intro)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                                    [_directive_dompurify_html, _ctx.intro]
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(FeaturedPosts, {
                            "featured-posts": _ctx.featuredPosts,
                            title: "Highlights"
                        }, null, 8, ["featured-posts"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("section", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(DatePicker),
                            _createVNode(FilterInput, {
                                options: _ctx.filterOptions,
                                color: "!bg-green-50 hover:!bg-grey-200",
                                title: "Event Type",
                                onSelectedValueChanged: addFilterSelectionToUrl
                            }, null, 8, ["options"])
                        ]),
                        (Object.keys(_ctx.posts).length === 0)
                            ? (_openBlock(), _createElementBlock("h3", _hoisted_7, " Sorry, there are no posts to display for your filter selection. Please try another. "))
                            : _createCommentVNode("", true),
                        (hasFirstThreePosts.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(firstThreePosts.value, (post) => {
                                    return (_openBlock(), _createBlock(_unref(PostCard), {
                                        post: post,
                                        class: "!opacity-100"
                                    }, null, 8, ["post"]));
                                }), 256))
                            ]))
                            : _createCommentVNode("", true),
                        (hasEventsSeries.value)
                            ? (_openBlock(), _createBlock(_unref(SignpostsBlock), {
                                key: 2,
                                posts: _ctx.eventsSeries,
                                title: _ctx.eventSeriesTitle || 'Our Event Series',
                                colorClasses: "bg-green-50"
                            }, null, 8, ["posts", "title"]))
                            : _createCommentVNode("", true),
                        (hasRemainingPosts.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(remainingPosts.value, (post) => {
                                    return (_openBlock(), _createBlock(_unref(PostCard), {
                                        post: post,
                                        class: "!opacity-100"
                                    }, null, 8, ["post"]));
                                }), 256))
                            ]))
                            : _createCommentVNode("", true),
                        (displayPagination.value)
                            ? (_openBlock(), _createBlock(_unref(ArchivePagination), {
                                key: 4,
                                class: "container",
                                pagination: _ctx.pagination
                            }, null, 8, ["pagination"]))
                            : _createCommentVNode("", true)
                    ])
                ]),
                (hasVirtualEvents.value)
                    ? (_openBlock(), _createElementBlock("section", _hoisted_10, [
                        _createVNode(FeaturedPosts, {
                            "featured-posts": _ctx.virtualEvents,
                            title: "Virtual Exhibitions"
                        }, null, 8, ["featured-posts"])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.pastEvents)
                    ? (_openBlock(), _createBlock(_unref(SignpostsBlock), {
                        key: 2,
                        posts: pastEventsData.value,
                        title: _ctx.pastEvents.title,
                        paragraph: _ctx.pastEvents.paragraph,
                        colorClasses: "bg-green-50"
                    }, null, 8, ["posts", "title", "paragraph"]))
                    : _createCommentVNode("", true),
                (_ctx.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_11, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
