import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, normalizeStyle as _normalizeStyle, unref as _unref } from "vue";
const _hoisted_1 = {
    class: "!mt-10 flex flex-col gap-10 lg:mt-20 lg:gap-20",
    "data-archive-read-and-watch": ""
};
const _hoisted_2 = {
    key: 0,
    class: "container"
};
const _hoisted_3 = { class: "wysiwyg w-full lg:w-2/3" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = {
    key: 0,
    class: "container mt-10 text-center"
};
const _hoisted_6 = { "data-quicklinks": "" };
import CollectionCarousel from '@molecules/CollectionCarousel.vue';
import FeaturedPosts from '@molecules/FeaturedPosts.vue';
import { reactive, defineAsyncComponent } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ArchiveReadAndWatch',
    props: {
        intro: {
            type: String,
            required: true,
        },
        featuredPosts: {
            type: Object,
            required: false,
        },
        quickLinksSections: {
            type: Object,
            required: false,
        },
        collections: {
            type: Object,
            required: true,
        },
    },
    setup(__props) {
        const QuickLinks = defineAsyncComponent(() => import('@molecules/QuickLinks.vue'));
        const state = reactive({
            loadMore: false,
        });
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", _hoisted_1, [
                    (__props.intro)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                                [_directive_dompurify_html, __props.intro]
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createVNode(FeaturedPosts, {
                        "featured-posts": __props.featuredPosts,
                        title: "Highlights"
                    }, null, 8, ["featured-posts"])
                ]),
                (__props.collections)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.collections.slice(0, 6), (collection, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                                collection
                                    ? (_openBlock(), _createBlock(CollectionCarousel, {
                                        key: 0,
                                        collection: collection,
                                        index: `${index}`
                                    }, null, 8, ["collection", "index"]))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 256)),
                        _createVNode(_TransitionGroup, {
                            "enter-active-class": "slide-up__enter-active",
                            "enter-from-class": "slide-up__enter-from",
                            "enter-to-class": "slide-up__enter-to",
                            "leave-active-class": "slide-up__enter-active",
                            "leave-from-class": "slide-up__enter-to",
                            "leave-to-class": "slide-up__enter-from"
                        }, {
                            default: _withCtx(() => [
                                (__props.collections.length > 6 && !state.loadMore)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createElementVNode("button", {
                                            class: "text-sm font-bold",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (state.loadMore = true))
                                        }, " Load more ")
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }),
                        _createVNode(_TransitionGroup, {
                            "enter-active-class": "slide-up__enter-active",
                            "enter-from-class": "slide-up__enter-from",
                            "enter-to-class": "slide-up__enter-to"
                        }, {
                            default: _withCtx(() => [
                                (__props.collections.length > 5 && state.loadMore)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.collections.slice(6), (collection, index) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            collection
                                                ? (_openBlock(), _createBlock(CollectionCarousel, {
                                                    key: 0,
                                                    collection: collection,
                                                    index: `${index}`,
                                                    style: _normalizeStyle({ transitionDelay: `${index * 200 + 500}ms` })
                                                }, null, 8, ["collection", "index", "style"]))
                                                : _createCommentVNode("", true)
                                        ], 64));
                                    }), 256))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true),
                (__props.quickLinksSections)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.quickLinksSections, (quickLinks) => {
                        return (_openBlock(), _createElementBlock("section", _hoisted_6, [
                            _createVNode(_unref(QuickLinks), { quickLinks: quickLinks }, null, 8, ["quickLinks"])
                        ]));
                    }), 256))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
