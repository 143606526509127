import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import EmbedIframe from '@atoms/EmbedIframe.vue';
import ImageLandscape from '@atoms/ImageLandscape.vue';
import VideoLandscape from '@atoms/VideoLandscape.vue';
import { computed } from 'vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeroMedia',
    props: {
        image: {},
        mobileImage: {},
        videoEmbed: {},
        mobileVideoEmbed: {},
        videoFile: {},
        mobileVideoFile: {}
    },
    setup(__props) {
        const screen = useMq();
        const props = __props;
        //Embed
        const hasDesktopVideoEmbed = computed(() => {
            return props.videoEmbed && props.videoEmbed.length > 0;
        });
        const hasMobileVideoEmbed = computed(() => {
            return props.mobileVideoEmbed && props.mobileVideoEmbed.length > 0;
        });
        const desktopVideoEmbed = computed(() => {
            return props.videoEmbed;
        });
        const mobileVideoEmbed = computed(() => {
            return props.mobileVideoEmbed;
        });
        // const embedClasses: ComputedRef<string> = computed(() => {
        //     return 'relative w-full flex justify-center items-center overflow-hidden herovideoEmbed';
        // });
        const embedClasses = computed(() => {
            let classes = [];
            classes.push('relative w-full flex justify-center items-center overflow-hidden herovideoEmbed');
            if (hasMobileVideoEmbed.value) {
                classes.push('lg:!aspect-banner');
            }
            else {
                classes.push('!aspect-banner');
            }
            return classes.join(' ');
        });
        //Video File
        const hasMobileVideoFile = computed(() => {
            return Object.keys(props.mobileVideoFile).length > 0;
        });
        const hasDesktopVideoFile = computed(() => {
            return Object.keys(props.videoFile).length > 0;
        });
        const desktopVideoFile = computed(() => {
            return props.videoFile;
        });
        const mobileVideoFile = computed(() => {
            return props.mobileVideoFile;
        });
        //Image
        const hasMobileImage = computed(() => {
            return Object.keys(props.mobileImage).length > 0;
        });
        const hasDesktopImage = computed(() => {
            return Object.keys(props.image).length > 0;
        });
        const desktopImage = computed(() => {
            return props.image;
        });
        const mobileImage = computed(() => {
            return props.mobileImage;
        });
        //mobile checks
        const hasMobileMedia = computed(() => {
            return (hasMobileImage.value ||
                hasMobileVideoEmbed.value ||
                hasMobileVideoFile.value);
        });
        const displayMobileMedia = computed(() => {
            return hasMobileMedia.value && screen.mdMinus;
        });
        return (_ctx, _cache) => {
            return (displayMobileMedia.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (hasMobileVideoFile.value)
                        ? (_openBlock(), _createBlock(VideoLandscape, {
                            key: 0,
                            video: mobileVideoFile.value,
                            removeMaxWidth: true,
                            controls: false,
                            autoplay: true,
                            muted: true,
                            loop: true
                        }, null, 8, ["video"]))
                        : (hasMobileVideoEmbed.value)
                            ? (_openBlock(), _createBlock(EmbedIframe, {
                                key: 1,
                                embed: mobileVideoEmbed.value,
                                embedClasses: embedClasses.value,
                                autoplay: true
                            }, null, 8, ["embed", "embedClasses"]))
                            : (hasMobileImage.value)
                                ? (_openBlock(), _createBlock(ImageLandscape, {
                                    key: 2,
                                    forcedRatio: true,
                                    image: mobileImage.value,
                                    lazyLoadImage: "eager"
                                }, null, 8, ["image"]))
                                : _createCommentVNode("", true)
                ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (hasDesktopVideoFile.value)
                        ? (_openBlock(), _createBlock(VideoLandscape, {
                            key: 0,
                            video: desktopVideoFile.value,
                            removeMaxWidth: true,
                            controls: false,
                            autoplay: true,
                            loop: true,
                            muted: true
                        }, null, 8, ["video"]))
                        : (hasDesktopVideoEmbed.value)
                            ? (_openBlock(), _createBlock(EmbedIframe, {
                                key: 1,
                                embed: desktopVideoEmbed.value,
                                embedClasses: embedClasses.value,
                                autoplay: true
                            }, null, 8, ["embed", "embedClasses"]))
                            : (hasDesktopImage.value)
                                ? (_openBlock(), _createBlock(ImageLandscape, {
                                    key: 2,
                                    forcedRatio: false,
                                    image: desktopImage.value,
                                    lazyLoadImage: "eager"
                                }, null, 8, ["image"]))
                                : _createCommentVNode("", true)
                ], 64));
        };
    }
});
