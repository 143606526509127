import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { "data-page-header": "" };
import ParentPageHeader from '@molecules/page-headers/ParentPageHeader.vue';
import ChildPageHeader from '@molecules/page-headers/ChildPageHeader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PageHeader',
    props: {
        title: {},
        subtitle: {},
        postType: {},
        image: {},
        japaneseTranslation: {},
        contentAuthor: {},
        breadcrumbs: {},
        termDescription: {},
        totalSearchResults: {},
        is404: { type: Boolean },
        backgroundColor: {},
        isExhibition: { type: Boolean },
        exhibitionVideo: {},
        searchTerm: {},
        useJapaneseFont: { type: Boolean }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("header", _hoisted_1, [
                (_ctx.breadcrumbs)
                    ? (_openBlock(), _createBlock(ChildPageHeader, {
                        key: 0,
                        backgroundColor: _ctx.backgroundColor,
                        breadcrumbs: _ctx.breadcrumbs,
                        contentAuthor: _ctx.contentAuthor,
                        image: _ctx.image,
                        japaneseTranslation: _ctx.japaneseTranslation,
                        title: _ctx.title,
                        subtitle: _ctx.subtitle,
                        termDescription: _ctx.termDescription,
                        isExhibition: _ctx.isExhibition,
                        exhibitionVideo: _ctx.exhibitionVideo,
                        useJapaneseFont: _ctx.useJapaneseFont
                    }, null, 8, ["backgroundColor", "breadcrumbs", "contentAuthor", "image", "japaneseTranslation", "title", "subtitle", "termDescription", "isExhibition", "exhibitionVideo", "useJapaneseFont"]))
                    : (_openBlock(), _createBlock(ParentPageHeader, {
                        key: 1,
                        backgroundColor: _ctx.backgroundColor,
                        breadcrumbs: _ctx.breadcrumbs,
                        contentAuthor: _ctx.contentAuthor,
                        image: _ctx.image,
                        japaneseTranslation: _ctx.japaneseTranslation,
                        title: _ctx.title,
                        totalSearchResults: _ctx.totalSearchResults,
                        searchTerm: _ctx.searchTerm,
                        useJapaneseFont: _ctx.useJapaneseFont
                    }, null, 8, ["backgroundColor", "breadcrumbs", "contentAuthor", "image", "japaneseTranslation", "title", "totalSearchResults", "searchTerm", "useJapaneseFont"]))
            ]));
        };
    }
});
