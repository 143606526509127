import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
const _hoisted_2 = { class: "grid-layout lg:container !gap-y-3" };
import SectionHeader from '@molecules/SectionHeader.vue';
import { useMq } from 'vue3-mq';
import SignpostsImages from '@molecules/SignpostsImages.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SignpostsBlock',
    props: {
        posts: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        paragraph: {
            type: String,
            required: true,
        },
        colorClasses: {
            type: String,
            required: false,
        },
        forcedRatio: {
            type: Boolean,
            required: false,
            default: false,
        },
        anchor: {
            type: String,
            required: false,
        },
    },
    setup(__props) {
        const screen = useMq();
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", {
                id: __props.anchor,
                class: _normalizeClass([{ [__props.colorClasses]: _unref(screen).lgPlus }, "!my-20 lg:!m-0 lg:py-10"]),
                "data-signposts-block": ""
            }, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(SectionHeader, {
                        colorClasses: __props.colorClasses,
                        subtitle: __props.paragraph,
                        title: __props.title,
                        class: "md:col-span-2 lg:col-span-1 lg:!p-0",
                        innerContainerClasses: "container lg:!px-0",
                        isSignpost: true
                    }, null, 8, ["colorClasses", "subtitle", "title"]),
                    _createElementVNode("div", {
                        class: _normalizeClass([{ container: _unref(screen).mdMinus }, "flex flex-col gap-10 overflow-hidden md:col-span-2 md:flex-row md:gap-5"])
                    }, [
                        _createVNode(SignpostsImages, {
                            forcedRatio: __props.forcedRatio,
                            posts: __props.posts
                        }, null, 8, ["forcedRatio", "posts"])
                    ], 2)
                ])
            ], 10, _hoisted_1));
        };
    }
});
