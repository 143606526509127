import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["onClick", "onKeyup"];
const _hoisted_2 = {
    class: "datePicker !bg-green-50 hover:!bg-grey-200",
    type: "button"
};
const _hoisted_3 = { class: "absolute bottom-0 right-1 top-0 flex flex-col items-center justify-center" };
const _hoisted_4 = {
    key: 1,
    class: "mr-2.5 h-4 w-4 cursor-pointer"
};
import { computed, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { endOfWeek, startOfWeek, addWeeks, isToday, isTomorrow } from 'date-fns';
import { hyphenate } from '@helpers/text';
import SvgIcon from '@atoms/SvgIcon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DatePicker',
    setup(__props) {
        const today = new Date();
        const presetDates = ref([
            { label: 'Today', value: [today, today] },
            {
                label: 'This Week',
                value: [
                    startOfWeek(today, {
                        weekStartsOn: 1,
                    }),
                    endOfWeek(today, {
                        weekStartsOn: 1,
                    }),
                ],
            },
            {
                label: 'Next Week',
                value: [
                    startOfWeek(addWeeks(today, 1), {
                        weekStartsOn: 1,
                    }),
                    endOfWeek(addWeeks(today, 1), {
                        weekStartsOn: 1,
                    }),
                ],
            },
        ]);
        const date = ref();
        const handleDate = () => {
            const fromDate = date.value[0];
            const toDate = date.value[1];
            const newUrl = new URL(window.location.href);
            if (fromDate === toDate) {
                newUrl.searchParams.delete('from');
                newUrl.searchParams.delete('to');
                newUrl.searchParams.set('date', hyphenate(fromDate));
            }
            else {
                newUrl.searchParams.delete('date');
                newUrl.searchParams.set('from', hyphenate(fromDate));
                newUrl.searchParams.set('to', hyphenate(toDate));
            }
            window.history.pushState({}, '', newUrl);
            window.location.reload();
        };
        // Remove date from url
        const removeDate = (event) => {
            event.stopPropagation();
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('date');
            newUrl.searchParams.delete('from');
            newUrl.searchParams.delete('to');
            window.history.pushState({}, '', newUrl);
            window.location.reload();
        };
        // Get date from url and format it for the button
        const formatDate = computed(() => {
            const queryParams = new URLSearchParams(window.location.search);
            const date = queryParams.get('date');
            const from = queryParams.get('from');
            const to = queryParams.get('to');
            if (!date && !from && !to) {
                return null;
            }
            if (date) {
                return convertDateFormat(date);
            }
            return `${convertDateFormat(from)} to ${convertDateFormat(to)}`;
        });
        /**
         * Convert a date format from hyphen-separated to slash-separated.
         *
         * @param {string} date - The date string in hyphen-separated format.
         * @returns {string} - The date string in slash-separated format.
         */
        const convertDateFormat = (date) => {
            return date.replace(/-/g, '/');
        };
        // Main Computed Property
        const dateText = computed(() => {
            if (!formatDate.value) {
                return 'Date';
            }
            const parsedDate = new Date(formatDate.value.split('/').reverse().join('-'));
            if (isToday(parsedDate)) {
                return 'Today';
            }
            if (isTomorrow(parsedDate)) {
                return 'Tomorrow';
            }
            return formatDate.value;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(VueDatePicker), {
                modelValue: date.value,
                "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event) => ((date).value = $event)),
                    handleDate
                ],
                ref: "datepicker",
                clearable: false,
                "auto-apply": true,
                "enable-time-picker": false,
                "model-value": date.value,
                "preset-dates": presetDates.value,
                "month-change-on-scroll": false,
                range: "",
                format: "MM/dd/yyyy",
                light: "",
                placeholder: dateText.value,
                "model-type": "dd.MM.yyyy",
                "preview-format": "MM/dd/yyyy"
            }, {
                "preset-date-range-button": _withCtx(({ label, value, presetDate }) => [
                    _createElementVNode("span", {
                        tabindex: 0,
                        role: "button",
                        onClick: ($event) => (presetDate(value)),
                        onKeyup: [
                            _withKeys(_withModifiers(($event) => (presetDate(value)), ["prevent"]), ["enter"]),
                            _withKeys(_withModifiers(($event) => (presetDate(value)), ["prevent"]), ["space"])
                        ]
                    }, _toDisplayString(label), 41, _hoisted_1)
                ]),
                "arrow-right": _withCtx(() => [
                    _createVNode(SvgIcon, {
                        class: "",
                        name: "arrow"
                    })
                ]),
                "arrow-left": _withCtx(() => [
                    _createVNode(SvgIcon, {
                        class: "origin-center rotate-180 transform",
                        name: "arrow"
                    })
                ]),
                "dp-input": _withCtx((value) => [
                    _createElementVNode("div", null, [
                        _createElementVNode("button", _hoisted_2, _toDisplayString(dateText.value), 1),
                        _createElementVNode("div", _hoisted_3, [
                            (dateText.value !== 'Date')
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    class: "relative flex h-7 w-7 cursor-pointer items-center justify-center",
                                    onClick: removeDate
                                }, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                                        return _createElementVNode("span", {
                                            key: i,
                                            class: "absolute block h-px w-6 shrink-0 grow-0 origin-center transform bg-black opacity-100 transition-all ease-in-out first:-rotate-45 last:rotate-45"
                                        });
                                    }), 64))
                                ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                                    _createVNode(SvgIcon, { name: "chevron-down" })
                                ]))
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["modelValue", "model-value", "preset-dates", "placeholder"]));
        };
    }
});
