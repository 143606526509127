import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = ["controls", "autoplay", "muted", "loop"];
const _hoisted_3 = ["src", "type"];
const _hoisted_4 = {
    key: 0,
    class: "mt-2 font-serif lg:mt-5"
};
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VideoLandscape',
    props: {
        video: {
            type: Object,
            required: true,
        },
        removeMaxWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
        controls: {
            type: Boolean,
            required: false,
            default: true,
        },
        autoplay: {
            type: Boolean,
            required: false,
            default: false,
        },
        loop: {
            type: Boolean,
            required: false,
            default: false,
        },
        muted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        const props = __props;
        const hasVideo = computed(() => props.video && props.video.url);
        const videoRatio = computed(() => {
            if (props.video.width && props.video.height) {
                return props.video.width / props.video.height;
            }
            return 16 / 9;
        });
        const maxWidthStyle = computed(() => !props.removeMaxWidth ? '`max-width: ${video.width}px`' : '');
        return (_ctx, _cache) => {
            return (hasVideo.value)
                ? (_openBlock(), _createElementBlock("figure", _hoisted_1, [
                    _createElementVNode("video", {
                        style: _normalizeStyle([{ aspectRatio: videoRatio.value }, maxWidthStyle.value]),
                        class: "h-full w-full",
                        controls: __props.controls,
                        autoplay: __props.autoplay,
                        muted: __props.muted,
                        playsinline: "",
                        loop: __props.loop,
                        preload: "metadata"
                    }, [
                        _createElementVNode("source", {
                            src: __props.video.url,
                            type: __props.video.mime_type
                        }, null, 8, _hoisted_3),
                        _cache[0] || (_cache[0] = _createTextVNode(" Your browser does not support the video tag. "))
                    ], 12, _hoisted_2),
                    (__props.video.caption)
                        ? (_openBlock(), _createElementBlock("figcaption", _hoisted_4, _toDisplayString(__props.video.caption), 1))
                        : _createCommentVNode("", true)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
