import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    class: "w-full overflow-hidden",
    "data-signposts-images": ""
};
const _hoisted_2 = ["href", "rel", "target"];
import { computed, defineAsyncComponent } from 'vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SignpostsImages',
    props: {
        posts: {
            type: Object,
            required: true,
        },
        forcedRatio: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        const screen = useMq();
        const ImageLandscape = defineAsyncComponent(() => import('@atoms/ImageLandscape.vue'));
        const ButtonWithArrow = defineAsyncComponent(() => import('@atoms/ButtonWithArrow.vue'));
        const props = __props;
        const applyForcedRatio = computed(() => {
            return !!(screen.mdMinus || props.forcedRatio);
        });
        return (_ctx, _cache) => {
            return (__props.posts)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.posts, (post) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (post.url)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: post.url,
                                rel: post.target === '_blank' ? 'noopener noreferrer' : null,
                                target: post.target,
                                class: "group"
                            }, [
                                _createVNode(_unref(ImageLandscape), {
                                    forcedRatio: applyForcedRatio.value,
                                    image: post.image
                                }, null, 8, ["forcedRatio", "image"]),
                                _createVNode(_unref(ButtonWithArrow), {
                                    target: post.target,
                                    text: post.title !== '' ? post.title : undefined,
                                    url: post.url,
                                    class: "mt-5"
                                }, null, 8, ["target", "text", "url"])
                            ], 8, _hoisted_2))
                            : (_openBlock(), _createBlock(_unref(ImageLandscape), {
                                key: 1,
                                forcedRatio: applyForcedRatio.value,
                                image: post.image
                            }, null, 8, ["forcedRatio", "image"]))
                    ]));
                }), 256))
                : _createCommentVNode("", true);
        };
    }
});
