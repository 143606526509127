import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "!my-0 flex flex-col gap-3.5 lg:gap-8"
};
const _hoisted_2 = { class: "border-b border-black pb-3.5 font-bold first:font-light lg:pb-8" };
const _hoisted_3 = {
    key: 0,
    class: ""
};
const _hoisted_4 = ["href", "title"];
const _hoisted_5 = ["href", "title"];
const _hoisted_6 = {
    key: 0,
    class: "mt-1 block text-md"
};
const _hoisted_7 = {
    key: 1,
    class: "!my-0 !font-jp text-jp"
};
const _hoisted_8 = {
    key: 2,
    class: "!my-0 text-sm font-bold"
};
const _hoisted_9 = { key: 3 };
const _hoisted_10 = {
    key: 0,
    class: "relative !aspect-square h-full w-full overflow-hidden md:!aspect-video"
};
import { defineAsyncComponent, computed } from 'vue';
import { decodeHTML } from '@helpers/text';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ChildPageHeader',
    props: {
        title: {},
        subtitle: {},
        image: {},
        japaneseTranslation: {},
        contentAuthor: {},
        breadcrumbs: {},
        backgroundColor: {},
        termDescription: {},
        isExhibition: { type: Boolean },
        exhibitionVideo: {},
        useJapaneseFont: { type: Boolean }
    },
    setup(__props) {
        const screen = useMq();
        const ImageLandscape = defineAsyncComponent(() => import('@atoms/ImageLandscape.vue'));
        const EmbedIframe = defineAsyncComponent(() => import('@atoms/EmbedIframe.vue'));
        const stringWithComma = (string) => {
            if (string) {
                const decodedString = decodeHTML(string);
                return `${decodedString}, `;
            }
            return '';
        };
        const props = __props;
        const coloredBoxClasses = computed(() => {
            return [props.backgroundColor, 'pb-10 pt-4 lg:pt-10'];
        });
        const wrapperClasses = computed(() => {
            const classes = [];
            if (props.image?.url) {
                classes.push('grid-cols-1 lg:grid-cols-2 lg:pt-5 2xl:grid-cols-3');
            }
            else {
                classes.push('!grid-cols-1 !gap-x-0 !gap-y-0');
            }
            if (screen.lgPlus) {
                classes.push('container');
            }
            if (props.isExhibition) {
                classes.push('!gap-y-0');
            }
            return classes;
        });
        const hasExhibitionVideo = computed(() => {
            return !!props.exhibitionVideo.desktopVideo;
        });
        const getExhibitionVideo = computed(() => {
            if (screen.smMinus && props.exhibitionVideo?.mobileVideo) {
                return props.exhibitionVideo.mobileVideo;
            }
            return props.exhibitionVideo.desktopVideo;
        });
        const hasImage = computed(() => {
            return !!props.image?.url;
        });
        const assignedFont = computed(() => {
            return props.useJapaneseFont ? '!font-jp' : '';
        });
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["grid gap-x-5 gap-y-3", wrapperClasses.value])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(coloredBoxClasses.value)
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.backgroundColor.length > 0 ? 'px-4 lg:px-5' : 'px-4 lg:pl-0',
                            "flex w-full flex-col gap-3.5 lg:gap-8"
                        ])
                    }, [
                        (_ctx.breadcrumbs)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb) => {
                                    return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        (breadcrumb.terms)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumb.terms, (term, index) => {
                                                    return (_openBlock(), _createElementBlock("a", {
                                                        href: term.url,
                                                        title: `Go back to ${term.title}`
                                                    }, [
                                                        (index < breadcrumb.terms.length - 1)
                                                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                _createTextVNode(_toDisplayString(stringWithComma(term.title)), 1)
                                                            ], 64))
                                                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                _createTextVNode(_toDisplayString(_unref(decodeHTML)(term.title)), 1)
                                                            ], 64))
                                                    ], 8, _hoisted_4));
                                                }), 256))
                                            ]))
                                            : (_openBlock(), _createElementBlock("a", {
                                                key: 1,
                                                href: breadcrumb.url,
                                                title: `Go back to ${breadcrumb.title}`
                                            }, _toDisplayString(_unref(decodeHTML)(breadcrumb.title)), 9, _hoisted_5))
                                    ]));
                                }), 256))
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("h1", {
                            class: _normalizeClass([assignedFont.value, "!mb-1"])
                        }, [
                            _createTextVNode(_toDisplayString(_unref(decodeHTML)(_ctx.title)) + " ", 1),
                            (_ctx.subtitle)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(decodeHTML)(_ctx.subtitle)), 1))
                                : _createCommentVNode("", true)
                        ], 2),
                        (_ctx.japaneseTranslation)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.japaneseTranslation), 1))
                            : _createCommentVNode("", true),
                        (_ctx.contentAuthor)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.contentAuthor), 1))
                            : _createCommentVNode("", true),
                        (_ctx.termDescription)
                            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, null, 512)), [
                                [_directive_dompurify_html, _ctx.termDescription]
                            ])
                            : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                (hasImage.value || hasExhibitionVideo.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([{ container: _unref(screen).mdMinus && !_ctx.isExhibition }, "flex w-full overflow-hidden 2xl:col-span-2"])
                    }, [
                        (hasExhibitionVideo.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_unref(EmbedIframe), {
                                    embed: getExhibitionVideo.value,
                                    class: "relative h-full w-full object-cover",
                                    embedClasses: "absolute left-1/2 top-1/2 !aspect-square h-full w-auto -translate-x-1/2 -translate-y-1/2 transform md:!aspect-exhibition",
                                    autoplay: true
                                }, null, 8, ["embed"])
                            ]))
                            : (_openBlock(), _createBlock(_unref(ImageLandscape), {
                                key: 1,
                                image: _ctx.image,
                                extraClasses: "h-full",
                                lazyLoadImage: "eager",
                                allowCaption: "",
                                class: "mb-6 lg:mb-0",
                                isChildPageHeader: ""
                            }, null, 8, ["image"]))
                    ], 2))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
