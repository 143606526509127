import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "aria-label", "aria-labelledby", "name"];
const _hoisted_3 = {
    disabled: "",
    selected: "",
    value: ""
};
const _hoisted_4 = ["selected", "value"];
import { hyphenate, decodeHTML } from '@helpers/text';
import { computed, ref, watch } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FilterInput',
    props: {
        options: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: 'bg-grey-50',
        },
    },
    setup(__props, { emit: __emit }) {
        const hasOptions = computed(() => {
            return props.options && Object.keys(props.options).length > 0;
        });
        const props = __props;
        const ariaLabel = computed(() => `Select a ${props.title}`);
        // Define a reactive variable to hold the selected value
        const selectedValue = ref('');
        const emit = __emit;
        const checkUrlForFilterTerm = (value) => {
            const urlParams = new URLSearchParams(window.location.search);
            const filteredContent = urlParams.get('filtered');
            if (filteredContent) {
                return filteredContent === value;
            }
        };
        const emitSelectedValue = (event) => {
            // Update the reactive variable with the selected value
            selectedValue.value = event.target.value;
            // Emit an event to the parent component with the selected value
            emit('selectedValueChanged', selectedValue.value);
        };
        const addFilterSelectionToUrl = (selectedValue) => {
            const newUrl = new URL(window.location.href);
            // Remove 'filtered' parameter if 'clear-filter' is selected
            if (selectedValue === 'clear-filter') {
                newUrl.searchParams.delete('filtered');
            }
            else {
                newUrl.searchParams.set('filtered', selectedValue);
            }
            // Remove '/page/' and a number if present
            const cleanedUrl = newUrl.toString().replace(/\/page\/\d+/g, '');
            window.history.pushState({}, '', cleanedUrl);
            window.location.reload();
        };
        watch(selectedValue, (newValue) => {
            addFilterSelectionToUrl(newValue);
        });
        return (_ctx, _cache) => {
            return (hasOptions.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("label", {
                        for: _unref(hyphenate)(__props.title),
                        class: "invisible absolute opacity-0"
                    }, " Select a " + _toDisplayString(__props.title) + ": ", 9, _hoisted_1),
                    _createElementVNode("select", {
                        id: _unref(hyphenate)(__props.title),
                        "aria-label": ariaLabel.value,
                        "aria-labelledby": _unref(hyphenate)(__props.title),
                        class: _normalizeClass([__props.color, "filter"]),
                        name: _unref(hyphenate)(__props.title),
                        onChange: emitSelectedValue
                    }, [
                        _createElementVNode("option", _hoisted_3, _toDisplayString(__props.title), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (option.slug)
                                    ? (_openBlock(), _createElementBlock("option", {
                                        key: 0,
                                        selected: checkUrlForFilterTerm(option.slug),
                                        value: option.slug
                                    }, _toDisplayString(_unref(decodeHTML)(option.name)), 9, _hoisted_4))
                                    : _createCommentVNode("", true)
                            ], 64));
                        }), 256)),
                        _cache[0] || (_cache[0] = _createElementVNode("option", { value: "clear-filter" }, "Clear filter", -1))
                    ], 42, _hoisted_2)
                ], 64))
                : _createCommentVNode("", true);
        };
    }
});
