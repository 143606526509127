import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "my-10 border-b border-black pb-10 lg:my-20 lg:pb-20",
    "data-event-info": ""
};
const _hoisted_2 = { class: "grid grid-cols-1 gap-5 lg:grid-cols-2" };
const _hoisted_3 = { class: "flex w-full flex-col gap-7 lg:gap-10" };
const _hoisted_4 = {
    key: 0,
    class: "flex basis-auto items-start gap-4"
};
const _hoisted_5 = { class: "mt-1 h-3.5 w-3.5 shrink-0 grow-0" };
const _hoisted_6 = {
    key: 0,
    class: "h6-alt !my-0"
};
const _hoisted_7 = {
    key: 1,
    class: "!my-0"
};
const _hoisted_8 = { class: "flex w-full flex-col gap-7 lg:gap-10" };
const _hoisted_9 = {
    key: 3,
    class: "-mt-3.5 pl-8 lg:-mt-5"
};
import { computed } from 'vue';
import ListItemWithIcon from '@atoms/ListItemWithIcon.vue';
import SvgIcon from '@atoms/SvgIcon.vue';
import ImageLandscape from '@atoms/ImageLandscape.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EventInfo',
    props: {
        hasLiveStream: { type: Boolean },
        admissionInfo: {},
        eventLocation: {},
        eventAdditionalInfo: {},
        isPastEvent: { type: Boolean },
        eventSessions: {},
        eventDates: {},
        isExternalEvent: { type: Boolean },
        externalEventProviderLogo: {}
    },
    setup(__props) {
        const props = __props;
        /**
         * Computed
         */
        const hasEventDate = computed(() => props.eventDates.date && Object.keys(props.eventDates.date).length > 0);
        const hasEventLocation = computed(() => props.eventLocation?.length > 0);
        const hasAdmissionInfo = computed(() => props.admissionInfo?.length > 0);
        const hasLiveStream = computed(() => props.hasLiveStream);
        const hasAdditionalInfo = computed(() => props.eventAdditionalInfo?.length > 0);
        const additionalInfoTitle = computed(() => {
            if (props.isExternalEvent)
                return '';
            return 'Additional Information';
        });
        const displayExternalEventProviderLogo = computed(() => props.externalEventProviderLogo?.url?.length > 0);
        const hasInfo = computed(() => hasEventDate.value ||
            hasEventLocation.value ||
            hasAdmissionInfo.value ||
            hasLiveStream.value ||
            hasAdditionalInfo.value);
        const liveStreamText = computed(() => {
            if (props.isPastEvent) {
                return 'The event was also live streamed online.';
            }
            return 'The event will also be live streamed online.';
        });
        return (_ctx, _cache) => {
            return (hasInfo.value)
                ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("ul", _hoisted_3, [
                            (hasEventDate.value)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                                    _createElementVNode("div", _hoisted_5, [
                                        _createVNode(SvgIcon, { name: "calendar" })
                                    ]),
                                    _createElementVNode("div", null, [
                                        (_ctx.eventDates.date)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.eventDates.date), 1))
                                            : _createCommentVNode("", true),
                                        (_ctx.eventDates.time)
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.eventDates.time), 1))
                                            : _createCommentVNode("", true)
                                    ])
                                ]))
                                : _createCommentVNode("", true),
                            (hasEventLocation.value)
                                ? (_openBlock(), _createBlock(ListItemWithIcon, {
                                    key: 1,
                                    content: _ctx.eventLocation,
                                    class: "!basis-auto",
                                    icon: "pin"
                                }, null, 8, ["content"]))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("ul", _hoisted_8, [
                            (hasAdmissionInfo.value)
                                ? (_openBlock(), _createBlock(ListItemWithIcon, {
                                    key: 0,
                                    title: _ctx.admissionInfo,
                                    class: "!basis-auto",
                                    icon: "ticket"
                                }, null, 8, ["title"]))
                                : _createCommentVNode("", true),
                            (hasLiveStream.value)
                                ? (_openBlock(), _createBlock(ListItemWithIcon, {
                                    key: 1,
                                    icon: "wifi",
                                    class: "!basis-auto",
                                    content: liveStreamText.value
                                }, null, 8, ["content"]))
                                : _createCommentVNode("", true),
                            (hasAdditionalInfo.value)
                                ? (_openBlock(), _createBlock(ListItemWithIcon, {
                                    key: 2,
                                    icon: "chevron",
                                    class: "!basis-auto",
                                    title: additionalInfoTitle.value,
                                    content: _ctx.eventAdditionalInfo
                                }, null, 8, ["title", "content"]))
                                : _createCommentVNode("", true),
                            (displayExternalEventProviderLogo.value)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                                    _createVNode(ImageLandscape, {
                                        forcedRatio: false,
                                        image: _ctx.externalEventProviderLogo,
                                        extraClasses: "h-full !w-auto",
                                        imageClasses: "h-full !w-auto",
                                        class: "m h-full max-h-24 w-auto"
                                    }, null, 8, ["image"])
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
