import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "mt-1 h-3.5 w-3.5 shrink-0 grow-0"
};
const _hoisted_2 = {
    key: 0,
    class: "h6-alt mb-2"
};
const _hoisted_3 = { key: 1 };
import SvgIcon from '@atoms/SvgIcon.vue';
import { useMq } from 'vue3-mq';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ListItemWithIcon',
    props: {
        title: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: false,
        },
        extraClasses: {
            type: String,
            required: false,
            default: '',
        },
        removeDesktopIcons: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(__props) {
        const screen = useMq();
        const props = __props;
        const removeDesktopIcons = () => {
            if (props.removeDesktopIcons)
                return !!screen.lgPlus;
        };
        let formattedContent = props.content;
        // check if content does not contain <p> tags, if not wrap it in <p> tags
        if (props.content && !props.content.includes('<p>')) {
            formattedContent = `<p>${props.content}</p>`;
        }
        return (_ctx, _cache) => {
            const _directive_dompurify_html = _resolveDirective("dompurify-html");
            return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass([__props.extraClasses, "flex basis-1/3 items-start gap-4"])
            }, [
                (!removeDesktopIcons())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(SvgIcon, { name: __props.icon }, null, 8, ["name"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                    (__props.title)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(__props.title), 1))
                        : _createCommentVNode("", true),
                    (_unref(formattedContent))
                        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, null, 512)), [
                            [_directive_dompurify_html, _unref(formattedContent)]
                        ])
                        : _createCommentVNode("", true)
                ])
            ], 2));
        };
    }
});
